import axios from 'axios';
import Cookies from "js-cookie";

const getToken = Cookies.get("token");

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    // 'ngrok-skip-browser-warning': true,
    Authorization: `Bearer ${getToken && JSON.parse(getToken.split('j:')[1]).token}`,
  },
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log('error info', error);

    if (error.response) {
      const status = error.response.status;
    }
    return Promise.reject(error);
  }
);

const responseBody = (response) => response.data;

const requests = {
  get: (url) => instance.get(url).then(responseBody),

  post: (url, body) =>
    instance
      .post(url, body)
      .then(responseBody),

  put: (url, body) => instance.put(url, body, {}).then(responseBody),

  delete: (url, config) => instance.delete(url, config).then(responseBody),
};

export const employeeApi={
    applyLeave:(body)=>requests.post('/addleave/',body),
    addTask:(body)=>requests.post('/addtask/', body),
    getCalender:(body)=>requests.post('/calenderviewdb/',body),
    deleteCalenderActivity:(body)=>requests.delete('/calenderviewdb/',{ data: body }),
    retriveTasks:(body)=>requests.post('/resourcelistbyuser/',body),
    retriveLeave:(body)=>requests.post('/getemployeeleaves/',body),
    getEmployeeProjectsByDate:(body)=>requests.post('/linkedemployeebyproject/',body)
}

export const employeeTimesheet={
  UserActivity:(body)=>requests.post('/activitybyuserdb/', body),
  retriveResourse:(body)=>requests.post('/resourcelistbyuser/vijay/', body)
}

export const Project={
  addProject:(body)=>requests.post('/addinternalproject/', body),
  getAllProjects:(body)=>requests.post('/getallprojects/', body),
  getProjects:()=>requests.get('/getallprojects/'),
  getEmployeeProject:(body)=>requests.post('/employeeprojects/', body)
}

export const Activity={
  addActivity:(body)=>requests.post('/addactivity/',body),
  getActivity:()=>requests.get('/addactivity/')
}

export const linkEmployees={
  getAllEmployeesBasedOnProject: (body) => requests.post('/getallemployees/', body),
  getAllEmployees:()=>requests.get('/getallemployees/'),
  addTimesheet:(body)=>requests.post('addtimesheet/',body),
  getLinkedEmployee:()=>requests.get('/projectemployeelist/'),
  employeeHistory:(body)=>requests.post('/employeeprojecthistory/',body)
}

export const adminEmployeeView={
  getEmployeeView:(body)=>requests.post('/employeeview/',body)
}
export const adminProjectView={
  getProjectView:(body)=>requests.post('/projectview/',body)
}

export const deliveryManager={
  getApprovalProject:(body)=>requests.post('/getprojectbyapprover/',body),
  approvalProject:(body)=>requests.post('/approvetimesheet/',body),
  getEmployeeByApprover:(body)=>requests.post('/getprojectemployeesbyapprover/',body),
  approveorrejectTimesheet:(body)=>requests.post('/approveorrejecttimesheet/',body)
}

export const approval={
  getChatData:(body)=>requests.post('/gettimesheetcomments/',body),
  sendApproval:(body)=>requests.post('/submittimesheetforapproval/',body)
}

export const report={
  generateReport:(body)=>requests.post('/generatemonthlyreports/',body),
  getReport:(body)=>requests.post('/getreport/',body),
}