import React, { useEffect, useState } from "react";
import { Button, Flex, Input, message, notification } from "antd";
import ChatItem from "../../utils/ChatItem";
import { approval } from "../../api";
import { employeeId } from "../../utils/userDetails";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

const ChatCommentForm = ({
  refreshChat,
  projectId,
  year,
  month,
  hide,
  approverNo,
}) => {
  const { TextArea } = Input;
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };
  const [chatMessages, setChatMessages] = useState([]);
  const [currentComment, setCurrentComment] = useState("Please approve my timesheet");
  const getChatMessagges = async () => {
    try {
      const response = await approval.getChatData({
        projectId,
        employeeNo: employeeId,
        year,
        month,
        approverNo,
      });
      const customChat = response?.comments.map((data) => ({
        employeeId: data.employeeNo,
        employeeName: data.employeeName,
        message: data.comment,
      }));
      setChatMessages(customChat);
    } catch (error) {
      console.log("error in chat response", error, error?.response?.data?.msg);
      
    }
  };
  useEffect(() => {
    setChatMessages([]);
    getChatMessagges();
  }, [refreshChat, approverNo]);
  const handleComment = (e) => {
    setCurrentComment(e.target.value);
  };
  const handleSendApproval = async () => {
    try {
        const response=await approval.sendApproval({
          projectId,
          year,
          month,
          employeeNo: employeeId,
          comment: currentComment ? currentComment : "Please approve my timesheet",
        });
        openNotification(
          response.message,
            <CheckCircleFilled style={{color: "#52c41a" }} />
          );
    } catch (error) {
      console.log(error,error?.response?.data?.msg )
      openNotification(
        error?.response?.data?.msg ,
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
        );
    } finally {
      setCurrentComment(null)
      hide(false);
    }
  };
  return (
    <div
      className="formScroll"
      style={{ marginBottom: "1rem", maxHeight: "700px", overflowY: "scroll" }}
    >
      <ChatItem messages={chatMessages} myEmployeeId={employeeId} />
      <Flex vertical gap={"1.5rem"}>
        <div style={{display: "flex"}}>
          <span style={{ color: "red" }}>*</span>
          <span>Add comments before submitting timesheet for approval</span>
        </div>
        <TextArea
          showCount
          maxLength={100}
          value={currentComment}
          onChange={handleComment}
          placeholder="Add Your Comment"
          style={{
            height: 120,
            resize: "none",
          }}
        />
        <Button
          type="primary"
          size="large"
          style={{ width: "120px", alignSelf: "end" }}
          onClick={handleSendApproval}
        >
          Send
        </Button>
        {contextHolder}
      </Flex>
    </div>
  );
};

export default ChatCommentForm;
