import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, Flex, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/employeeTimesheet.css";
import { employeeTimesheet } from "../api";
import TableComponent from "../utils/TableComponent";
import { formatNewDate } from "../utils/dateFormat";

export default function DeliveryManagerView() {
  const location = useLocation();
  const navigate = useNavigate();
  const { name, month, year, employeeId, projectName, employeeName } =
    location.state;
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    {
      title: "Date",
      dataIndex: "logDate",
      key: "logDate",
      align: "center",
      width: "15%",
      render: (_, { date }) => formatNewDate(date),
    },
    {
      title: "Time Worked",
      dataIndex: "timeWorked",
      key: "hours",
      align: "center",
      width: "15%",
      render: (_, { timeWorked }) =>
        `${Math.floor(timeWorked / 60)} h ${timeWorked % 60} min`,
    },
    {
      title: "Description",
      dataIndex: "task_performed",
      key: "desc",
      align: "left",
      render: (text, record, index) => {
        if (text?.length !== 0 && !record.leave_type) {
          return <div dangerouslySetInnerHTML={{ __html: text }} />;
        } else if (record.leave_type) {
          return (
            <Flex gap={"0.5rem"}>
              <b style={{ color: "red" }}>Leave:</b>
              <div
                style={{ color: "red" }}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            </Flex>
          );
        } else {
          return "";
        }
      },
    },
  ];

  const [loading, setLoading] = useState(false);
  const [errorShown, setErrorShown] = useState(false);

  const timeSheet = async () => {
    try {
      setLoading(true);
      const response = await employeeTimesheet.UserActivity({
        projectId: name,
        employeeNo: employeeId,
        year: year,
        month: month,
      });
      const filteredData = response.data;
      if (filteredData) {
        filteredData?.sort((a, b) => new Date(a.logDate) - new Date(b.logDate));
        const newDataSource = filteredData.map((item) => ({
          date: item.logDate,
          task_performed: item.comment,
          timeWorked: item.minutesWorked,
          leave_type: item.isLeave,
        }));
        setDataSource(newDataSource);
      }
    } catch (error) {
      if (!errorShown) {
        setErrorShown(true);
        if (error.message === "Network Error") {
          message.error("Network connection failed. Try again.");
        } else {
          message.error("An error occurred while fetching data.");
        }
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (month && name && year) {
      timeSheet();
    }
  }, [year, month, name]);

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: dataSource.length,
    });
  };

  return (
    <Flex vertical gap={"2rem"} style={{ padding: 24 }}>
      <Flex
        vertical
        gap={"1rem"}
        style={{
          backgroundColor: "#e6f4ff",
          height: "100px",
          borderRadius: "1rem",
          width: "100%",
          padding: "1rem",
          justifyContent:"space-between"
        }}
      >
        <Flex align="flex-start" justify="space-between" >
          <Tooltip placement="top" title="Back" color="8e8e8e" arrow={false}>
            <Button
              type="text"
              shape="circle"
              icon={
                <ArrowLeftOutlined
                  style={{ fontSize: "17px", color: "#6d6d6d" }}
                />
              }
              style={{ padding: "4px" }}
              onClick={() => navigate("/timesheet-approval")}
            />
          </Tooltip>
          
        </Flex>
        <Flex  align="flex-start" gap={'5rem'} >
        <div
           style={{ fontSize: "1rem" }}
          >
            <b>Project: &nbsp;</b>{projectName}
          </div>
          <div style={{ fontSize: "1rem" }}>
            <b>Employee: &nbsp;</b> {employeeName}
          </div>
        </Flex>
      </Flex>
      <div
        className="formScroll"
        style={{ maxHeight: "70vh", overflowY: "scroll" }}
      >
        <TableComponent
          loading={loading}
          columns={columns}
          rows={dataSource}
          scroll={{ x: 1400 }}
          pagination={pagination}
          onChange={handleTableChange}
          locale={{ emptyText: "No data available for given input" }}
        />
      </div>
    </Flex>
  );
}
