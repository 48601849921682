// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radiogroup>*{
    width:250px;
    height:40px;
}

.radiogroup>*>span{
    transition: all ease-in;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}
.userbutton{
    position:absolute;
    width:86.5%;
    bottom: 0;
    margin-bottom: 40px;
}
.userbutton>*{
    width: 100%;
}
.timesheetModal>div>.ant-modal-content{
    padding-bottom: 0!important;
    padding-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/Form.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,SAAS;IACT,mBAAmB;AACvB;AACA;IACI,WAAW;AACf;AACA;IACI,2BAA2B;IAC3B,kBAAkB;AACtB","sourcesContent":[".radiogroup>*{\n    width:250px;\n    height:40px;\n}\n\n.radiogroup>*>span{\n    transition: all ease-in;\n    height: 100%;\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n    font-size: 16px;\n}\n.userbutton{\n    position:absolute;\n    width:86.5%;\n    bottom: 0;\n    margin-bottom: 40px;\n}\n.userbutton>*{\n    width: 100%;\n}\n.timesheetModal>div>.ant-modal-content{\n    padding-bottom: 0!important;\n    padding-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
