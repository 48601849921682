import { Flex, Modal, Select } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import { adminEmployeeView } from "../api";
import EditableEmployeeViewForm from "../components/Form/EditableEmployeeForm";
import TableComponent from "../utils/TableComponent";
import { employeeId } from "../utils/userDetails";
import { convertTime } from "../utils/dateFormat";

const generateCalendarData = (year, month) => {
  const startOfMonth = moment().year(year).month(month).startOf("month");
  const endOfMonth = moment(startOfMonth).endOf("month");

  let currentDate = startOfMonth.clone();
  const days = [];

  while (currentDate <= endOfMonth) {
    days.push({
      date: currentDate.date(),
      day: currentDate.format("dddd").slice(0, 3).toUpperCase(),
      dayOfWeek: currentDate.day(),
      isWeekend: currentDate.day() === 0 || currentDate.day() === 6,
    });
    currentDate.add(1, "day");
  }
  return days;
};

const EmployeeViewTimeSheet = () => {
  const monthArray = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const currentDate = moment();
  const [currentMonth, setCurrentMonth] = useState(currentDate.month());
  const [currentYear, setCurrentYear] = useState(currentDate.year());
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDay, setSelectedDay] = useState(null);
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [expandedWeeks, setExpandedWeeks] = useState({ 0: true });

  const handleYearChange = (value) => {
    setCurrentYear(value);
  };

  const handleMonthChange = (value) => {
    setCurrentMonth(value - 1);
  };

  const handleTableChange = (pagination) => {
    setPagination({
      ...pagination,
      total: employeeData.length,
    });
  };

  const createWeeks = (calendarData) => {
    const weeks = [];
    let currentWeek = [];
    const startDay = calendarData[0].dayOfWeek;

    for (let i = 0; i < startDay; i++) {
      currentWeek.push(null);
    }
    calendarData.forEach((item, index) => {
      currentWeek.push(item);
      if (item.dayOfWeek === 6 || index === calendarData.length - 1) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });

    while (currentWeek.length > 0 && currentWeek.length < 7) {
      currentWeek.push(null);
    }
    if (currentWeek.length > 0) {
      weeks.push(currentWeek);
    }
    if (weeks.length === 6 && !weeks[5].some((day) => day !== null)) {
      weeks.pop();
    }
    return weeks;
  };

  const calendarData = generateCalendarData(currentYear, currentMonth);
  const weeks = createWeeks(calendarData);

  // Toggle week collapse/expand
  const toggleWeek = (weekIndex) => {
    setExpandedWeeks((prevState) => ({
      ...prevState,
      [weekIndex]: !prevState[weekIndex],
    }));
  };

  const columns = [
    {
      title: "Project / Activity",
      dataIndex: "projectOrActivity",
      key: "projectOrActivity",
      fixed: "left",
      change: true,
      width: 200,
    },
    {
      title: "Activity",
      dataIndex: "project",
      key: "project",
      fixed: "left",
      width: 100,
      render: (text, record) => {
        return text ? (
          <RxCross1
            style={{
              margin: "0 0 0 1rem",
              color: "df0b2e",
              fontSize: "16px",
              textAlign: "center",
            }}
          />
        ) : (
          <IoCheckmarkOutline
            style={{
              margin: "0 0 0 1rem",
              color: "green",
              fontSize: "18px",
              textAlign: "center",
            }}
          />
        );
      },
    },
    ...weeks.map((week, weekIndex) => {
      const isExpanded = expandedWeeks[weekIndex];
      return {
        title: (
          <div
            style={{ cursor: "pointer", textAlign: "center" }}
            onClick={() => toggleWeek(weekIndex)}
          >
            Week {weekIndex + 1} {isExpanded ? "↑" : "↓"}
          </div>
        ),
        width: 100,
        children: isExpanded
          ? week
              .filter((item) => item !== null)
              .map((item, dayIndex) => {
                return {
                  title: (
                    <div style={{ textAlign: "center" }}>
                      <div>{item.day}</div>
                      <div style={{ fontSize: "14px" }}>
                        {item.date} {monthArray[currentMonth].slice(0, 3)}
                      </div>
                    </div>
                  ),
                  dataIndex: `day${item.date}`,
                  key: item.date,
                  width: 100,
                  change: false,
                  render: (text, record) => {
                    if (!record.logs[item.date - 1]) return null; // Handle cases where no logs are present

                    const minutesWorked = record.logs[item.date - 1]?.minutes;
                    const description = record.logs[item.date - 1]?.description;
                    const isOnLeave = record.logs[item.date - 1]?.isOnLeave;
                    const leaveType = record.logs[item.date - 1]?.leaveType;
                    return {
                      children: (
                        <div
                          onClick={() => {
                            if (!item.isWeekend) {
                              setSelectedDay({
                                ...item,
                                month: currentMonth,
                                minutesWorked,
                                leaveType,
                                description: description,
                                activityId: record?.activityId,
                                project: record?.projectId,
                                year: currentYear,
                              });
                              setIsModalVisible(true);
                            }
                          }}
                          style={{
                            cursor: item.isWeekend ? "default" : "pointer",
                            height: 55,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              minutesWorked / 60 >= 8
                                ? "#06c974"
                                : leaveType === 1 || leaveType === 3
                                ? "#df0b2e"
                                : "#000000",
                          }}
                        >
                          {isOnLeave
                            ? leaveType === 1
                              ? "Leave"
                              : leaveType === 3
                              ? "Holiday"
                              : convertTime(minutesWorked)
                            : convertTime(minutesWorked)}
                        </div>
                      ),
                      props: {
                        style: {
                          background: item.isWeekend ? "pink" : "white",
                          padding: 0,
                        },
                      },
                    };
                  },
                };
              })
          : [],
      };
    }),
    {
      title: "Total Time",
      key: "totalHours",
      fixed: "right",
      align: "center",
      width: 80,
      render: (text, record) => {
        const totalMinutes = record.logs.reduce(
          (acc, curr) => acc + curr.minutes,
          0
        );
        return <b>{convertTime(totalMinutes)}</b>;
      },
    },
  ];

  const employeeView = async () => {
    try {
      setLoading(true);
      const response = await adminEmployeeView.getEmployeeView({
        employeeId: employeeId,
        month: currentMonth,
        year: currentYear,
      });
      let newResponse;
      const projectArray = response.projects.map((row) => ({
        ...row,
        projectOrActivity: row.name,
        project: true,
      }));
      const activityArray = response.activities.map((row) => ({
        ...row,
        projectOrActivity: row.name,
        project: false,
      }));
      newResponse = [...projectArray, ...activityArray];
      setEmployeeData(newResponse);
    } catch (error) {
      console.log("employee view error", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (((currentMonth || currentMonth === 0) && currentYear) || isFormOpen) {
      setEmployeeData([]);
      employeeView();
      setIsFormOpen(false);
    }
  }, [currentMonth, currentYear, isFormOpen]);

  // let totalWorkingHours = 0;
  // let totalWorkingMinutes = 0;
  // employeeData?.forEach((employee) => {
  //   employee.logs?.forEach((data, index) => {
  //     totalWorkingMinutes += data.minutes;
  //   });
  // });

  // totalWorkingHours += Math.floor(totalWorkingMinutes / 60);
  // totalWorkingMinutes = totalWorkingMinutes % 60;

  return (
    <div
      style={{
        padding: 24,
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <Flex justify="space-between" align="center" gap="2rem">
        <div style={{ display: "flex", gap: "1rem" }}>
          <div>
            <div className="selectDropDown">Year</div>
            <Select
              placeholder="Select Year"
              defaultValue={currentYear}
              onChange={handleYearChange}
              size="large"
              style={{ width: "100px", textAlign: "left" }}
            >
              {Array.from(
                { length: 5 },
                (_, i) => currentDate.year() - 1 + i
              ).map((year) => (
                <Select.Option key={year} value={year}>
                  {year}
                </Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <div className="selectDropDown">Month</div>
            <Select
              placeholder="Select Month"
              defaultValue={currentMonth + 1}
              onChange={handleMonthChange}
              size="large"
              style={{ width: "120px", textAlign: "left" }}
            >
              {monthArray.map((month, index) => (
                <Select.Option key={index} value={index + 1}>
                  {month}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
      </Flex>
      <TableComponent
        loading={loading}
        rows={employeeData}
        columns={columns}
        scrollx={1200}
        pagination={pagination}
        onChange={handleTableChange}
        summary={true}
        isActivity={true}
        locale={{ emptyText: "No data available for given input" }}
      />

      <Modal
        // title={
        //   <div style={{ fontSize: "22px", textAlign: "center" }}>Edit Log</div>
        // }
        visible={isModalVisible}
        footer={null}
        width={550}
        onCancel={() => setIsModalVisible(false)}
      >
        <EditableEmployeeViewForm
          hide={setIsModalVisible}
          formSubmit={setIsFormOpen}
          selectedDay={selectedDay}
        />
      </Modal>
    </div>
  );
};

export default EmployeeViewTimeSheet;
