import React from "react";
import pathConstants from "./pathConstants";
import CalendarView from "../../employeePages/CalenderView";
import TimeSheet from "../../employeePages/Timesheet";
import EmployeeViewTimeSheetEmp from "../../employeePages/EmployeeViewTimeSheetEmp";
import DeliveryManager from "../../components/DeliveryManager";
import DeliveryMangerView from "../../components/DeliveryMangerView";
import { isDeliveryManager } from "../../utils/userDetails";
import ProjectViewTimeSheet from "../../adminPages/ProjectViewTimeSheet";
import EmployeeViewTimeSheet from "../../adminPages/EmployeeViewTimeSheet";


let user=isDeliveryManager
 const Employeeroutes=[
    {path:pathConstants.Home,element:<CalendarView/>},
    {path:pathConstants.Timesheet,element:<TimeSheet/>},
    {path:pathConstants.EmployeeView,element:<EmployeeViewTimeSheetEmp/>},
    { path: pathConstants.ProjectViewTimesheet, element: <ProjectViewTimeSheet /> },
    { path: pathConstants.EmployeeViewTimesheet, element: <EmployeeViewTimeSheet /> },
]
if (user) {
    Employeeroutes.push({
      path: pathConstants.DeliveryManager,
      element: <DeliveryManager />,
    },

    );
    Employeeroutes.push({
    path: pathConstants.view,
    element: <DeliveryMangerView />,
    });

  }
export default Employeeroutes