import dayjs from 'dayjs';
const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;
    return formattedDate;
  };

const formatNewDate=(date)=>{
  const newDate=new Date(date)
  return dayjs(newDate).format('DD-MM-YYYY')
}  

const convertTime=(totalTime)=>{
  const minutes = Math.floor(totalTime / 60);
  const seconds = totalTime % 60;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
  
  return `${formattedMinutes}:${formattedSeconds}`
  
}

export {formatDate,formatNewDate,convertTime}
