import {  isDeliveryManager, userRole } from "../../utils/userDetails";

let user=userRole;
let user2=isDeliveryManager
const pathConstants={
    Home:'/',
    Timesheet:'/timesheet',
    EmployeeViewEmp:'/my-view',
    AddProject:'/addProject',
    AddNewTimeSheet:'/add-timesheet',
    ProjectView:'/project-view',
    EmployeeView:'/employee-view',
    EmployeeHistory:'/employee-history'
}
if (user2) {
    pathConstants.DeliveryManager = '/timesheet-approval';
    pathConstants.view='/delivery-manager-view'
}
export default pathConstants