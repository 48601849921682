// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-layout-has-sider{
    background-color:white;
    min-height: 88vh;
}

.ant-layout-sider-children{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.side-bar{
    height: calc(100vh - 64px);
}
.sidebar-menu-item{
    font-size: 16px;
    display: flex !important;
    /* align-items: center; */
    gap: 0.5rem;
}
.main-layout{
    height: calc(100vh - 64px);
}
.sub-group>div{
    display: flex !important;
    /* gap: 1rem; */
    text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/Styles/mainLayout.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,mDAAmD;AACvD;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,eAAe;IACf,wBAAwB;IACxB,yBAAyB;IACzB,WAAW;AACf;AACA;IACI,0BAA0B;AAC9B;AACA;IACI,wBAAwB;IACxB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".ant-layout-has-sider{\n    background-color:white;\n    min-height: 88vh;\n}\n\n.ant-layout-sider-children{\n    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;\n}\n.side-bar{\n    height: calc(100vh - 64px);\n}\n.sidebar-menu-item{\n    font-size: 16px;\n    display: flex !important;\n    /* align-items: center; */\n    gap: 0.5rem;\n}\n.main-layout{\n    height: calc(100vh - 64px);\n}\n.sub-group>div{\n    display: flex !important;\n    /* gap: 1rem; */\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
