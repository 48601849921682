import { Form, Input, Typography } from "antd";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";

const EmployeeViewForm = ({ hide, selectedDay }) => {
  const [form] = Form.useForm();
  const { Text } = Typography;
  useEffect(() => {
    if (selectedDay) {
      const hours = Math.floor(selectedDay.minutesWorked / 60);
      const minutes = selectedDay.minutesWorked % 60;
      form.setFieldsValue({ description: selectedDay.description.description });
      form.setFieldsValue({
        hoursWorked: `${
          hours
            ? minutes
              ? `${hours > 9 ? hours : "0" + hours}:${
                  minutes > 9 ? minutes : "0" + minutes
                }`
              : `${hours > 9 ? hours : "0" + hours}:00`
            : `00:${minutes > 9 ? minutes : "0" + minutes}`
        }`,
      });
    }
  }, [selectedDay, form]);
  const onFinish = async (values) => {
    try {
      hide();
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleDescription = (value) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, "text/html");
    const textContent = doc.body.textContent;
    if (textContent) {
      form.setFieldsValue({
        description: value,
      });
    } else {
      form.setFieldsValue({
        description: "",
      });
    }
  };

  return (
    <Form
      form={form}
      layout={"vertical"}
      initialValues={{
        layout: "vertical",
        remember: true,
      }}
      onFinish={onFinish}
      style={{
        padding: "1.5rem 1rem 0rem",
        margin: "auto",
        overflow: "hidden",
      }}
      className="userForm"
    >
      <div className="form">
        <Form.Item
          name="hoursWorked"
          label={<Text style={{ fontSize: "16px" }}>Total Time</Text>}
          rules={[{ required: true, message: "Hours is required" }]}
          style={{ margin: "0 0 24px" }}
        >
          <Input
            placeholder="Total time"
            size="large"
            readOnly
            style={{ width: "100%" }}
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={<Text style={{ fontSize: "16px" }}>Description</Text>}
          rules={[
            {
              required: true,
              message: "Please add Description",
            },
          ]}
        >
          <div
            style={{
              borderRadius: "2px",
              overflow: "hidden",
              zIndex: "10",
              height: "250px",
              marginBottom: "1rem",
            }}
          >
            <ReactQuill
              style={{
                width: "100%",
                height: "200px",
              }}
              value={selectedDay.description.description}
              onChange={handleDescription}
              theme="snow"
              modules={{
                toolbar: [
                  [{ font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                ],
              }}
              readOnly={true}
            />
          </div>
        </Form.Item>
      </div>
    </Form>
  );
};

export default EmployeeViewForm;
