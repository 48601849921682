import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const getToken = Cookies?.get("token");

let employeeId = "";
let roleName = "";
let isDeliveryManager = "";
let userNameEmpId = {};
let timesheetAdmin = false;

try {
  const decodedToken = jwtDecode(
    getToken,
    process.env.REACT_APP_JWT_SECRET_KEY
  );

  // const decodedToken = {
  //   "employeeNo": "SPAN-BLR-2027",
  //   "name": "Ayush Singhal",
  //   "isAdmin": false,
  //   "email": "ayushs@spanidea.com",
  //   "portalAccess": [
  //     {
  //       "roleName": "Admin",
  //       "portalName": "TIME SHEET"
  //     }
  //   ],
  //   "timesheetDeliveryManager": true,
  //   "iat": 1726139358,
  //   "exp": 1727003358
  // };

  employeeId = decodedToken.employeeNo;
  isDeliveryManager = decodedToken.timesheetDeliveryManager;
  userNameEmpId = { name: decodedToken.name, empId: decodedToken.employeeNo };

  const timesheetRole = decodedToken.portalAccess.find(
    (portal) => portal.portalName === "TIME SHEET"
  );

  if (timesheetRole) {
    timesheetAdmin = timesheetRole.roleName === "Admin";
    roleName = timesheetRole.roleName;
  }
  const listOfPortalName = decodedToken.portalAccess;

  if (decodedToken?.isAdmin) {
    roleName = "Admin";
  } else if (
    !decodedToken?.isAdmin &&
    !listOfPortalName.some((role) => role.portalName === "TIME SHEET")
  ) {
    window.location.href = "https://stagingapps.spanidea.com/login";
  } else {
    roleName = listOfPortalName.find(
      (role) => role.portalName === "TIME SHEET"
    ).roleName;
  }
} catch (error) {
  console.log(error);
  window.location.href = "https://stagingapps.spanidea.com/login";
}

const GetRole = () => {
  if (roleName === "Admin") {
    return "admin";
  }
  if (roleName === "View") {
    return "";
  }
};

const userRole = GetRole();

export { userRole, employeeId, isDeliveryManager, timesheetAdmin, userNameEmpId };
