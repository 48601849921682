import {
  CheckCircleFilled,
  CloseCircleFilled,
  EditOutlined,
  MinusOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Form,
  Modal,
  Radio,
  Select,
  Space,
  Switch,
  Tooltip,
  Typography,
  notification,
  Spin,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "../../Styles/Form.css";
import { Project, linkEmployees } from "../../api";

const AddNewTimeSheetForm = ({
  hide,
  linkedEmp,
  Open,
  selectedNewProject = null,
}) => {

  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employees2, setEmployees2] = useState([]);
  const [project, setProject] = useState([]);
  const [internalProject, setInternalProject] = useState([]);
  const [deliveryManager, setDeliveryManager] = useState([]);
  const [productManager, setProductManager] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [employeeLinkedProject, setEmployeeLinkedProject] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [currentCount, setCurrentCount] = useState(0);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [disabledFields, setDisabledFields] = useState([]);
  const [modalEmployeeIndex, setModalEmployeeIndex] = useState(null);
  const [modalBillable, setModalBillable] = useState(false);
  const [modalPrevBillable, setModalPrevBillable] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalPrevActive, setModalPrevActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalEndDate, setModalEndDate] = useState("");
  const [modalForm] = Form.useForm();
  const { Text, Title } = Typography;
  const radioOptions = [
    { label: "Client Project", value: "Project" },
    { label: "Internal Project", value: "Internal Project" },
  ];
  const [value, setValue] = useState("Project");
  const [formLoading, setFormLoading] = useState(false);

  useEffect(() => {
    getAllEmployees();
    getAllProjects();
  }, []);

  useEffect(() => {
    if (Open) {
      getLinkedEmployeeTable();
    }
  }, [Open]);

  const getAllEmployees = async () => {
    try {
      const response = await linkEmployees.getAllEmployees();
      const newResponse = response.employees.map((employee) => {
        return {
          value: employee.employeeNo,
          label: `${employee.employeeName}  (${employee.employeeNo})`,
        };
      });
      setEmployees(newResponse);
      setEmployees2(newResponse);
      setDeliveryManager(newResponse);
      setProductManager(newResponse);
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  const getAllProjects = async () => {
    try {
      const response = await Project.getProjects();
      setProject(
        response.projects.map((projects) => {
          return {
            label: projects.projectName,
            value: projects.projectId,
          };
        })
      );
      setInternalProject(
        response.internalProjects.map((projects) => {
          return {
            label: projects.projectName,
            value: projects.projectId,
          };
        })
      );
    } catch (error) {
      console.log("get all employee error", error);
    }
  };

  const getLinkedEmployeeTable = async () => {
    try {
      setFormLoading(true);
      const response = await linkEmployees.getLinkedEmployee();
      if (response && Array.isArray(response)) {
        setEmployeeLinkedProject(response);
      }
      setFormLoading(false);
    } catch (error) {
      console.log("linked Employee Error", error);
    }
  };

  const openNotification = (message, icon) => {
    api.open({
      message: message,
      duration: 2,
      icon: icon,
    });
  };

  const onFinish = async (values) => {

    const deliveryManagerId = values.deliveryManagerId;
    if (typeof deliveryManagerId === "object") {
      values.deliveryManagerId = deliveryManagerId.value;
    }
    const productManagerId = values.productManagerId;
    if (typeof productManagerId === "object") {
      values.productManagerId = productManagerId.value;
    }

    const formattedValues = {
      ...values,
      projectId: values.projectId.value,
      employees: values.employees.map((employee) => ({
        employeeNo: employee.employeeNo.value,
        isBillable: employee.isBillable,
        startDate: employee.endDate
          ? employee.endDate
          : employee.startDate.format("YYYY-MM-DD"),
        isActive: "Active" in employee ? employee.Active : true,
      })),
      isInternalProject: value === "Project" ? false : true,
    };

    try {
      setLoading(true);
      await linkEmployees.addTimesheet(formattedValues);
      linkedEmp(true);
      openNotification(
        "Employees Linked Successfully !!",
        <CheckCircleFilled style={{ color: "#52c41a" }} />
      );
      form.resetFields();
      setSelectedProject(null);
      setCurrentCount(0);
      getLinkedEmployeeTable();
    } catch (error) {
      form.resetFields();
      setCurrentCount(0);
      openNotification(
        "Failed to Add Link Employees",
        <CloseCircleFilled style={{ color: "#ff4d4f" }} />
      );
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
      setEmployees(deliveryManager);
      setEmployees2(productManager);
      setSelectedEmployee([]);
      form.resetFields();
      setValue("Project");
      setDisabledFields([]);
      setIsProjectSelected(false);
      hide(false);
      setSelectedProject(null);
      setModalEndDate(null);
    }
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(() => {
    if (selectedNewProject) {
      form.resetFields();
      setModalEndDate(null);
      setSelectedProject(selectedNewProject.projectId);
      let projectDetails = employeeLinkedProject.find(
        (proj) => proj.projectId === selectedNewProject.projectId
      );
      if (projectDetails) {
        const projectEmp = projectDetails.employees.filter(
          ({ endDate }) => !endDate
        );
        setCurrentCount(projectEmp.length);
        projectDetails = { ...projectDetails, employees: projectEmp };
        setValue(
          selectedNewProject.isInternal ? "Internal Project" : "Project"
        );
        form.setFieldsValue({
          projectId: {
            label: projectDetails.projectName,
            value: projectDetails.projectId,
          },
          employees:
            projectDetails.employees.length > 0
              ? projectDetails.employees.map((employee) => ({
                  employeeNo: {
                    label: employee.name,
                    value: employee.employeeNo,
                  },
                  startDate: moment(employee.startDate),
                  isBillable: employee.isBillable,
                  Active: employee.isActive,
                }))
              : [{}],
        });
        if (projectDetails.deliveryManager !== "") {
          form.setFieldsValue({
            deliveryManagerId: {
              label: projectDetails.deliveryManager,
              value: projectDetails.deliveryManagerId,
            },
          });
        }
        if (projectDetails.productManager !== "") {
          form.setFieldsValue({
            productManagerId: {
              label: projectDetails.productManager,
              value: projectDetails.productManagerId,
            },
          });
        }
        const selectedEmployees = projectDetails.employees.map((employee) => ({
          value: employee.employeeNo,
          label: employee.name,
        }));
        setSelectedEmployee(selectedEmployees);
        const remainingEmployees = deliveryManager.filter(
          (employee) =>
            !selectedEmployees.some(
              (selected) => selected.value === employee.value
            )
        );
        const remainingEmployees2 = productManager.filter(
          (employee) =>
            !selectedEmployees.some(
              (selected) => selected.value === employee.value
            )
        );
        setEmployees(remainingEmployees);
        setEmployees2(remainingEmployees2);
        setIsProjectSelected(true);
        setDisabledFields(projectDetails.employees.map((_, index) => index));
        

      }
    } else {
      form.resetFields();
    }
  }, [selectedNewProject]);

  useEffect(() => {
    if (selectedProject && employeeLinkedProject) {
      form.resetFields();
      let projectDetails = employeeLinkedProject.find(
        (proj) => proj.projectId === selectedProject
      );
      if (projectDetails) {
        const projectEmp = projectDetails.employees.filter(
          ({ endDate }) => !endDate
        );
        setCurrentCount(projectEmp.length);
        projectDetails = { ...projectDetails, employees: projectEmp };
        form.setFieldsValue({
          projectId: {
            label: projectDetails.projectName,
            value: projectDetails.projectId,
          },
          deliveryManagerId: {
            label: projectDetails.deliveryManager,
            value: projectDetails.deliveryManagerId,
          },
          productManagerId: {
            label: projectDetails.productManager,
            value: projectDetails.productManagerId,
          },
          employees:
            projectDetails.employees.length > 0
              ? projectDetails.employees.map((employee) => ({
                  employeeNo: {
                    label: employee.name,
                    value: employee.employeeNo,
                  },
                  startDate: moment(employee.startDate),
                  isBillable: employee.isBillable,
                  Active: employee.isActive,
                }))
              : [{}],
        });
        const selectedEmployees = projectDetails.employees.map((employee) => ({
          value: employee.employeeNo,
          label: employee.name,
        }));
        setSelectedEmployee(selectedEmployees);
        const remainingEmployees = deliveryManager.filter(
          (employee) =>
            !selectedEmployees.some(
              (selected) => selected.value === employee.value
            )
        );
        const remainingEmployees2 = productManager.filter(
          (employee) =>
            !selectedEmployees.some(
              (selected) => selected.value === employee.value
            )
        );
        setEmployees(remainingEmployees);
        setEmployees2(remainingEmployees2);
        setIsProjectSelected(true);
        setDisabledFields(projectDetails.employees.map((_, index) => index));
      } else {
        form.setFieldsValue({ projectId: selectedProject });
        form.setFieldsValue({
          employees: [{}],
        });
        setIsProjectSelected(true);
        setDisabledFields([]);
      }
    }
  }, [selectedProject, employeeLinkedProject]);

  const onChange = (e) => {
    setValue(e.target.value);
    setDisabledFields([]);
    setCurrentCount(0);
    form.resetFields();
  };

  const handleBillable = (index, e) => {
    const newFields = [...form.getFieldValue("employees")];
    newFields[index].isBillable = e.target.checked;
    form.setFieldsValue({ employees: newFields });
  };

  const handleActive = (index, e) => {
    const newFields = [...form.getFieldValue("employees")];
    newFields[index].Active = e.target.checked;
    form.setFieldsValue({ employees: newFields });
  };

  const handleEmployees = (event, index) => {
    const selectedEmployeeObj = employees.find(
      (employee) => employee.value === event.value
    );
    // Get the current list of employees from the form
    const currentEmployees = form.getFieldValue("employees");
    // Find the employee that is being replaced (if any)
    const oldEmployee = currentEmployees[index].employee;
    // Remove the old employee from the selectedEmployee list
    setSelectedEmployee((prevSelected) => {
      const newSelected = prevSelected.filter(
        (employee) => employee.value !== oldEmployee
      );
      newSelected[index] = selectedEmployeeObj;
      // Update the employees list to exclude the new selected employees
      setEmployees(
        deliveryManager.filter(
          (employee) =>
            !newSelected.some((selected) => selected.value === employee.value)
        )
      );
      setEmployees2(
        productManager.filter(
          (employee) =>
            !newSelected.some((selected) => selected.value === employee.value)
        )
      );
      return newSelected;
    });

    // Update the form's employee field with the new selection
    const newEmployees = [...currentEmployees];
    newEmployees[index].employeeNo = event;
    form.setFieldsValue({ employees: newEmployees });
  };

  const handleRemove = (name) => {
    const employeeToRemove = form.getFieldValue([
      "employees",
      name,
      "employee",
    ]);
    if (employeeToRemove) {
      const removedEmployee = selectedEmployee.find(
        (employee) => employee.value === employeeToRemove
      );
      setEmployees((prev) => [...prev, removedEmployee]);
      setSelectedEmployee((prev) =>
        prev.filter((employee) => employee.value !== employeeToRemove)
      );
    }
  };

  const handleEndDateChange = (date) => {
    setModalEndDate(date);
  };

  const handleEditEmployee = (index) => {
    const employeesList = form.getFieldValue("employees");

    if (index >= 0 && index < employeesList.length) {
      const employee = employeesList[index];

      form.setFieldsValue({
        Active: employee.Active,
        isBillable: employee.isBillable,
      });
      setModalEmployeeIndex(index);
      setModalBillable(employee.isBillable);
      setModalActive(employee.Active);
      setModalPrevActive(employee.Active);
      setModalPrevBillable(employee.isBillable);
      setModalEndDate(employee.endDate ? moment(employee.endDate) : null);

      // Show the modal
      setIsModalVisible(true);
    } else {
      console.error(`Invalid index ${index} for employeesList`);
    }
  };

  const handleModalCancel = () => {
    setModalEndDate("");
    setIsModalVisible(false);
  };

  const handleModalSave = (values) => {
    modalForm.validateFields().then((values) => {
      const employeeStartDate =
        form.getFieldValue("employees")[modalEmployeeIndex]?.startDate;
      const endDate = modalEndDate;

      if (endDate && endDate.isBefore(employeeStartDate, "day")) {
        notification.error({
          message: " ",
          description: "End Date should be greater than Start Date",
          duration: 2,
        });
        return;
      }

      const employeesFields = form.getFieldValue("employees");
      employeesFields[modalEmployeeIndex] = {
        ...employeesFields[modalEmployeeIndex],
        endDate: values.endDate ? values.endDate.format("YYYY-MM-DD") : null,
        isBillable: modalBillable,
        Active: modalActive,
      };
      form.setFieldsValue({ employees: employeesFields });
      setIsModalVisible(false);
      // setModalEndDate(null);
    });

    //   const { Active, isBillable, endDate } = values;
    //   const updatedEmployee = [...form.getFieldValue("employees")];
    //   // if (Active === false && isBillable) {
    //   //   updatedEmployee[modalEmployeeIndex] = {
    //   //     ...updatedEmployee[modalEmployeeIndex],
    //   //     isBillable: false,
    //   //     Active,
    //   //     endDate: endDate ? endDate.format("YYYY-MM-DD") : null,
    //   //   };
    //   //   form.setFieldsValue({ employees: updatedEmployee });
    //   //   setIsModalVisible(false);
    //   //   return;
    //   // }

    //   updatedEmployee[modalEmployeeIndex] = {
    //     ...updatedEmployee[modalEmployeeIndex],
    //     isBillable,
    //     Active,
    //     endDate: endDate ? endDate.format("YYYY-MM-DD") : null,
    //   };
    //   form.setFieldsValue({ employees: updatedEmployee });
    //   setIsModalVisible(false);
  };

  return (
    <> 
     {formLoading ? 
      <Spin 
      style={{
        height: "500px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }} /> 
      :
      <>
        <Form
          form={form}
          layout={"vertical"}
          initialValues={{
            layout: "vertical",
            remember: true,
          }}
          onFinish={onFinish}
          style={{
            padding: "1.5rem 0rem 0rem",
            margin: "auto",
            overflow: "hidden",
          }}
          className="userForm"
        >
          <div
            className="popupDiv"
            style={{ maxHeight: "60vh", overflowY: "auto", overflowX: "hidden" }}
          >
            <Form.Item style={{ margin: "0 auto 24px" }}>
              <Radio.Group
                options={radioOptions}
                onChange={onChange}
                value={value}
                optionType="button"
                disabled={selectedNewProject}
                style={{
                  width: "100%",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="radiogroup"
              />
            </Form.Item>
            <Form.Item
              name="projectId"
              label={<Text style={{ fontSize: "16px" }}>Project</Text>}
              rules={[
                {
                  required: true,
                  message: "Please Select Project",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Project Name"
                size="large"
                optionFilterProp="children"
                options={value === "Project" ? project : internalProject}
                onChange={(val) => {
                  setSelectedProject(val);
                  form.setFieldsValue({ projectId: val });
                  setIsProjectSelected(true);
                  setDisabledFields([]);
                }}
                disabled={selectedNewProject}
                filterOption={filterOption}
                value={selectedProject}
              />
            </Form.Item>

            <Form.Item
              name="deliveryManagerId"
              label={
                <Text style={{ fontSize: "16px" }}>
                  {value === "Project" ? "Delivery Manager" : "Project Lead"}
                </Text>
              }
              rules={[
                {
                  required: true,
                  message: `Please Select ${
                    value === "Project" ? "Delivery Manager" : "Project Lead"
                  }`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder={
                  value === "Project" ? "Delivery Manager" : "Project Lead"
                }
                size="large"
                options={deliveryManager}
                filterOption={filterOption}
                optionFilterProp="children"
              />
            </Form.Item>

            <Form.Item
              name="productManagerId"
              label={
                <Text style={{ fontSize: "16px" }}>{"Product Manager"}</Text>
              }
              rules={[
                {
                  required: true,
                  message: `Please Select ${"Product Manager"}`,
                },
              ]}
            >
              <Select
                showSearch
                placeholder={"Product Manager"}
                size="large"
                options={productManager}
                filterOption={filterOption}
                optionFilterProp="children"
              />
            </Form.Item>

            <div style={{ fontSize: "16px" }}>Employees</div>
            <Form.List
              name="employees"
              initialValue={[{}]}
              style={{ position: "absolute" }}
            >
              {(fields, { add, remove }) => (
                <>
                  <Form.Item
                    style={{
                      width: "fit-content",
                      position: "relative",
                      float: "right",
                      top: "-1.5rem",
                      marginBottom: 0,
                    }}
                  >
                    <Tooltip title="Add Employee">
                      <Button
                        shape="circle"
                        type="primary"
                        onClick={() => {
                          add();
                        }}
                        icon={<PlusOutlined />}
                      />
                    </Tooltip>
                  </Form.Item>

                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={key}
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                      }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "employeeNo"]}
                        label={<Text style={{ fontSize: "14px" }}>Name</Text>}
                        rules={[
                          {
                            required: true,
                            message: "Select Employee",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Add Employee"
                          style={{ width: "170px" }}
                          onChange={(event) => handleEmployees(event, index)}
                          options={employees}
                          labelInValue
                          disabled={disabledFields.includes(index)}
                          filterOption={filterOption}
                          optionFilterProp="children"
                          showSearch
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "startDate"]}
                        label={
                          <Text style={{ fontSize: "14px" }}>Start Date</Text>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please Select Date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "150px" }}
                          format={{
                            format: "YYYY-MM-DD",
                            type: "mask",
                          }}
                          disabled={disabledFields.includes(index)}
                        />
                      </Form.Item>
                      {!selectedNewProject?.isInternal ? (
                        <Form.Item
                          {...restField}
                          name={[name, "isBillable"]}
                          valuePropName="checked"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          initialValue={false}
                        >
                          <Checkbox
                            onChange={(e) => handleBillable(index, e)}
                            style={{
                              fontSize: "16px",
                              position: "relative",
                              bottom: "-2.2rem",
                            }}
                            disabled={disabledFields.includes(index)}
                          >
                            Billable
                          </Checkbox>
                        </Form.Item>
                      ) : (
                        <></>
                      )}

                      {index < currentCount && (
                        <Form.Item
                          {...restField}
                          name={[name, "Active"]}
                          valuePropName="checked"
                          rules={[
                            {
                              required: false,
                            },
                          ]}
                          initialValue={false}
                        >
                          <Checkbox
                            onChange={(e) => handleActive(index, e)}
                            style={{
                              fontSize: "16px",
                              position: "relative",
                              bottom: "-2.2rem",
                            }}
                            disabled={disabledFields.includes(index)}
                          >
                            Active
                          </Checkbox>
                        </Form.Item>
                      )}
                      {index < currentCount && (
                        <Form.Item>
                          <Button
                            icon={<EditOutlined />}
                            type="text"
                            style={{
                              color: "#2e77ff",
                              fontSize: "16px",
                              position: "relative",
                              bottom: "-2.2rem",
                            }}
                            onClick={() => handleEditEmployee(index)}
                          >
                            Edit
                          </Button>
                        </Form.Item>
                      )}
                      {index >= currentCount && (
                        <Form.Item>
                          <Tooltip title="Remove Employee">
                            <Button
                              shape="circle"
                              onClick={() => {
                                handleRemove(name);
                                remove(name);
                              }}
                              icon={<MinusOutlined />}
                              style={{
                                position: "absolute",
                                top: "1.9rem",
                              }}
                            />
                          </Tooltip>
                        </Form.Item>
                      )}
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </div>
          <Flex
            justify="end"
            gap="middle"
            style={{
              width: "100%",
              position: "relative",
              top: "0.5rem",
              marginLeft: "-16px",
            }}
            wrap="wrap"
          >
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Save
              </Button>
            </Form.Item>
          </Flex>
          {contextHolder}
        </Form>

        <Modal
          title={
            <div style={{ fontSize: "1.5rem", textAlign: "center" }}>
              Edit Employee Details
            </div>
          }
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          centered
          width={600}
        >
          <Form
            form={modalForm}
            layout="vertical"
            style={{ height: "250px", padding: "1rem 0 2rem 0" }}
            initialValues={{
              layout: "vertical",
              remember: true,
            }}
            onFinish={handleModalSave}
          >
            <Flex justify="space-between" style={{ marginBottom: "1rem" }}>
              <Text style={{ fontSize: "1rem" }}>
                <b>Employee:</b>{" "}
                {form.getFieldValue([
                  "employees",
                  modalEmployeeIndex,
                  "employeeNo",
                  "label",
                ])}
              </Text>
              <Text style={{ fontSize: "1rem" }}>
                <b>Start Date:</b>{" "}
                {moment(
                  form.getFieldValue([
                    "employees",
                    modalEmployeeIndex,
                    "startDate",
                  ])
                ).format("YYYY-MM-DD")}
              </Text>
            </Flex>

            {!selectedNewProject?.isInternal && (
              <Form.Item style={{ padding: 0, margin: 0 }} name="isBillable">
                <Flex
                  align="center"
                  gap={"1rem"}
                  style={{
                    flexDirection: "row-reverse",
                    justifyContent: "flex-end",
                  }}
                >
                  <Text style={{ fontSize: "0.95rem" }}>
                    Modify Billable Status
                  </Text>
                  <Switch
                    style={{ fontSize: "0.95rem" }}
                    checked={modalBillable}
                    onChange={(checked) => setModalBillable(checked)}
                  />
                </Flex>
              </Form.Item>
            )} 
            <Form.Item name="Active" style={{ marginBottom: "1rem" }}>
              <Flex
                align="center"
                gap={"1rem"}
                style={{
                  flexDirection: "row-reverse",
                  justifyContent: "flex-end",
                }}
              >
                <Text style={{ fontSize: "0.95rem" }}>Modify Active Status</Text>
                <Switch
                  style={{ fontSize: "0.95rem" }}
                  checked={modalActive}
                  onChange={(checked) => setModalActive(checked)}
                />
              </Flex>
            </Form.Item>

            <Form.Item
              name="endDate"
              label="End Date"
              rules={[
                {
                  required: true,
                  message: "Please select end date",
                },
              ]}
            >
              <DatePicker
                value={modalEndDate}
                onChange={handleEndDateChange}
                style={{ width: "250px" }}
                format={{
                  format: "YYYY-MM-DD",
                  type: "mask",
                }}
              />
            </Form.Item>

            <Flex align="center" justify="flex-end" gap={"1rem"}>
              <Button onClick={handleModalCancel}>Cancel</Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  value !== "Internal Project" &&
                  !modalPrevBillable &&
                  modalBillable != modalPrevBillable &&
                  !modalActive
                }
              >
                Save
              </Button>
            </Flex>
          </Form>
        </Modal>
      </>
      }
    </>
  );
};

export default AddNewTimeSheetForm;
