import React from "react";
import { Menu } from "antd";
import {
  CalendarOutlined,
  ProjectOutlined,
  CheckSquareOutlined,
  UserOutlined,
  FolderViewOutlined,
} from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import "../Styles/mainLayout.css";
import { isDeliveryManager } from "../utils/userDetails";

function CustomSider() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  let user = isDeliveryManager;

  const items = [
    {
      key: "1",
      icon: <CalendarOutlined />,
      label: "Calendar",
      path: "/",
    },
    {
      key: "2",
      icon: <ProjectOutlined />,
      label: "Timesheet",
      path: "/timesheet",
    },
    {
      key: "3",
      icon: <UserOutlined />,
      label: "My View",
      path: "/my-view",
    },
  ];
  
  if (user) {
    items.push(
      {
        key: "4",
        icon: <CheckSquareOutlined />,
        label: "Approve Timesheet",
        path: "/timesheet-approval",
      },
      {
        key: "5",
        icon: <FolderViewOutlined />,
        label: "View Timesheet",
        children: [
          {
            key: "6",
            label: "Project View",
            path: "/project-view",
          },
          {
            key: "7",
            label: "Employee View",
            path: "/employee-view",
          },
        ],
      }
    );
  }

  const selectedKey = items.find(
    (item) => location.pathname === item.path
  )?.key;

  return (
    <div className="contentWrapper">
      <Menu theme="light" selectedKeys={[selectedKey]} mode="inline">
        {items.map((item) =>
          item.children ? (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((child) => (
                <Menu.Item
                  key={child.key}
                  onClick={() => handleMenuItemClick(child.path)}
                >
                  {child.label}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            <Menu.Item
              key={item.key}
              icon={item.icon}
              onClick={() => handleMenuItemClick(item.path)}
            >
              {item.label}
            </Menu.Item>
          )
        )}
      </Menu>
    </div>
  );
}

export default CustomSider;
